import useSWRMutation from 'swr/mutation';

import { useAuthContext } from '../../../app/module/lib/hooks/useAuthContext';
import { IEventScheduleForm } from '../ui/interfaces/IEventScheduleForm';

import { addSlot } from './api/addSlot.api';
import { AddSlotOutput } from './api/interfaces/AddSlotOutput';
import { IAddSlot } from './api/interfaces/IAddSlot';

const useAddSlot = () => {
  const { trigger } = useSWRMutation(
    `/ss/slots/v1/add?zoneOffset=${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
    addSlot,
  );
  const { user } = useAuthContext();

  const onAdd = async (
    data: IEventScheduleForm,
    time: string,
  ): Promise<AddSlotOutput> => {
    const tempData: IAddSlot = {
      specialistId: String(user?.id),
      timePoint: time,
      kind: data.kind,
      duration: data.duration,
      capacity: 1,
    };

    return await trigger(tempData);
  };

  return { onAdd };
};

export default useAddSlot;
