import { memo, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import useSWRMutation from 'swr/mutation';

import { query } from '1_shared/api/apiInstance';
import { ISlot } from '1_shared/config/interfaces/ISlot';
import { ConferenceTimeStatus } from '1_shared/constants/conference';
import { Page } from '1_shared/ui';

import { RoutePath } from '../../1_shared/config/routes';
import ConferenceRoom from '../../4_widgets/ConferenceWidgets/ConferenceRoom/ui/ConferenceRoom';

import styles from './ConferencePage.module.scss';

const preEndMinutes = 10;
const postEndMinutes = 0;
const endMinutes = -30;

const ConferencePage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [confStatus, setConfStatus] =
    useState<ConferenceTimeStatus>('continue');
  const [isEnded, setIsEnded] = useState<boolean>(false);
  const [currTime, setCurrTime] = useState<Date | null>(null);

  const { data, trigger } = useSWRMutation(
    `/ss/slots/v1/get/${params.id}?zoneOffset=${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
    query<null, ISlot>(),
  );

  useEffect(() => {
    if (!(params?.id && params.id !== 'undefined')) {
      navigate(RoutePath.MAIN);
    } else {
      trigger();
    }
  }, [navigate, params.id, trigger]);

  useEffect(() => {
    if (data) {
      const isTimeLeft =
        dayjs(data.timePoint)
          .add(data.duration as number, 'minutes')
          .diff(dayjs(new Date()), 'minutes') <= endMinutes;
      if (isTimeLeft) {
        setIsEnded(true);
        setConfStatus('end');
      }
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      setTimeout(() => {
        const isTimeLeft =
          dayjs(data.timePoint)
            .add(data.duration as number, 'minutes')
            .diff(dayjs(new Date()), 'minutes') < endMinutes;
        if (isTimeLeft) {
          setIsEnded(true);
          setConfStatus('end');
        } else {
          setCurrTime(new Date());
        }
      }, 1000);
    }
  }, [data, currTime]);

  useEffect(() => {
    if (confStatus !== 'end') {
      if (
        dayjs(data?.timePoint)
          .add(data?.duration as number, 'minutes')
          .diff(dayjs(new Date()), 'minutes') < preEndMinutes
      ) {
        setConfStatus('preEnd');
      }
      if (
        dayjs(data?.timePoint)
          .add(data?.duration as number, 'minutes')
          .diff(dayjs(new Date()), 'minutes') <= postEndMinutes
      ) {
        setConfStatus('postEnd');
      }
    }
  }, [data, currTime, confStatus]);

  return !data ? null : (
    <Page>
      <div className={styles.root}>
        <ConferenceRoom
          slot={data}
          confStatus={confStatus}
          isEnded={isEnded}
          setIsEnded={setIsEnded}
        />
      </div>
    </Page>
  );
};
export default memo(ConferencePage);
