import { Swiper, SwiperSlide } from 'swiper/react'; // Используем ту же библиотеку, только уже для реакта

import MacBookImg from '1_shared/assets/images/mac-book@2x.png'; // Таким образом импортируем картинки

import { ISpecialistByIdData } from '../../../1_shared/config/interfaces/ISpecialistByIdData';

import 'swiper/scss';
import 'app/styles/global/global.scss';
import './specialist-theme.scss'; // Импортируем нужные нам стили
import './specialist-quote.scss';
import './specialist-meeting.scss';

const AboutSpecialist = ({ spec }: { spec: ISpecialistByIdData }) => (
  // оборачиваем html код в стрелочную функцию, перед этим необходимо назвать файл *.tsx, где * - это название вашего шаблона
  <div>
    <section className="specialist-theme">
      <div className="specialist-theme__container container">
        <h2 className="specialist-theme__title section-title">Темы</h2>
      </div>
      <Swiper // таким образом мы вызываем компонент из библиотеки, ранее вы к тегам добавляли классы
        spaceBetween={20}
        slidesPerView="auto"
        className="specialist-theme__slider swiper"
        id="specialistThemeSlider"
        wrapperClass="specialist-theme__list"
        setWrapperSize
      >
        {spec.keyThemes.map(theme => (
          <SwiperSlide key={theme} className="specialist-theme__item">
            {theme}
          </SwiperSlide>
        ))}
      </Swiper>
    </section>

    {spec.aboutYourself && (
      <section className="specialist-quote">
        <div className="specialist-quote__container container">
          <h2 className="specialist-quote__title section-title">
            Цитата о себе
          </h2>
          <div className="specialist-quote__content">
            <p>{spec.aboutYourself}</p>
          </div>
        </div>
      </section>
    )}

    <section className="specialist-meeting">
      <div className="specialist-meeting__container container">
        <div className="specialist-meeting__content">
          <h2 className="specialist-meeting__title section-title">
            Как будет выглядеть встреча со специалистом
          </h2>
          <p>Онлайн-сессии с психологом максимально комфортны</p>
        </div>
        <div className="specialist-meeting__image">
          <img src={MacBookImg} alt="встреча со специалистом" />
        </div>
      </div>
    </section>
  </div>
);

export default AboutSpecialist; // експортируем функцию от сюда
