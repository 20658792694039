import { Link } from 'react-router-dom';

import LogoFooterSvg from '1_shared/assets/pictures/logo-footer.svg';
import LogoFooterMobileSvg from '1_shared/assets/pictures/logo-footer-mobile.svg';

import { footerLinks } from '../config/footerLinks';

import 'app/styles/global/global.scss';
import './footer.scss';

const Footer = () => (
  <footer className="footer">
    <div className="container">
      <div className="footer__inner">
        <div className="footer__row">
          <div className="footer__half footer__half--min">
            <a href="/" className="footer__logo">
              <picture>
                <source
                  media="(max-width: 768px)"
                  srcSet={LogoFooterMobileSvg}
                />
                <img
                  src={LogoFooterSvg}
                  alt="Dotherapy ваша точка опоры"
                  width="166"
                  height="64"
                />
              </picture>
            </a>
          </div>
        </div>

        <div className="footer__row">
          <div className="footer__half footer__contacts">
            <div className="footer__contact">
              <p>Email</p>
              <a href="mailto:info@dotherapy.ru">info@dotherapy.ru</a>
            </div>
            <div className="footer__contact">
              <p>Телефон</p>
              <a href="tel:+78005553857">+7 (800) 555-38-57</a>
            </div>
          </div>
          <div className="footer__half">
            <ul className="footer__nav">
              {footerLinks.map(item =>
                item.isAppLink ? (
                  <li key={item.label} className="footer__nav-item">
                    <Link to={item.url} className="footer__nav-link">
                      {item.label}
                    </Link>
                  </li>
                ) : (
                  <li key={item.label} className="footer__nav-item">
                    <a href={item.url} className="footer__nav-link">
                      {item.label}
                    </a>
                  </li>
                ),
              )}
            </ul>
          </div>
        </div>

        <div className="footer__row">
          <div className="footer__half footer__half--min">
            <p className="footer__subtitle">Мы в социальных сетях</p>
            <ul className="footer__socials">
              <li className="footer__socials-item">
                <a
                  href="https://vk.com/dotherapy"
                  aria-label="ВКонтакте"
                  className="icon--vk"
                />
              </li>
              <li className="footer__socials-item">
                <a
                  href="https://t.me/do_therapy"
                  aria-label="Телеграм"
                  className="icon--tg"
                />
              </li>
              <li className="footer__socials-item">
                <a
                  href="https://m.youtube.com/@DoTherapy"
                  aria-label="Ютуб"
                  className="icon--yt"
                />
              </li>
              <li className="footer__socials-item">
                <a
                  href="https://dzen.ru/dotherapy_ru"
                  aria-label="Спектрум"
                  className="icon--spectrum"
                />
              </li>
            </ul>
          </div>
          <div className="footer__half">
            <a
              href="https://docs.google.com/document/d/1MEXfHKppmUVnfBEJNmSKZu7sIkh4Cj6BOo6ugjyRUUA/edit?usp=sharing"
              className="footer__agreement footer__subtitle"
            >
              Пользовательское соглашение
            </a>
            <p className="footer__copyright">
              © 2024 ООО «Психологические консультации онлайн» ИНН 7730264961
              ОГРН 1217700244673
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
