import { ReactElement } from 'react';
import { Switch } from 'antd';

import { ISpecialistListFilters } from '../../../../1_shared/config/interfaces';
import { experienceOptions } from '../../../../1_shared/constants/filterEnums';
import FilterSelect from '../FilterSelect/FilterSelect';

const SelectBlock = ({
  handleFormSubmit,
}: {
  handleFormSubmit: (data: ISpecialistListFilters) => void;
}): ReactElement => (
    <>
      {/* <FilterSelect */}
      {/*  name="specShowType" */}
      {/*  options={availableOptions} */}
      {/*  defaultValue="SHOW_ONLY_WITH_SLOTS" */}
      {/*  handleFormSubmit={handleFormSubmit} */}
      {/* /> */}
      <FilterSelect
        name="experienceSortOrder"
        options={experienceOptions}
        placeholder="Выберите для сортировки"
        handleFormSubmit={handleFormSubmit}
        allowClear
      />
    </>
  );

export default SelectBlock;
