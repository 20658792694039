import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import useSWRMutation from 'swr/mutation';

import { ERoles } from '1_shared/config/enums/ERoles';

import { ELoginStatus } from '../../../1_shared/config/enums/ELoginStatus';
import { UserRoutes } from '../../../1_shared/constants/userRoutes';
import { useAuthContext } from '../../../app/module/lib/hooks/useAuthContext';
import useMessages from '../../../app/module/lib/hooks/useMessages';
import { isPhoneField } from '../lib/checkPhone';
import { ILoginForm } from '../ui/interfaces/ILoginForm';

import { loginByCode, loginByPassword, sendCodeReq } from './api/auth.service';
import { IArgLogin } from './intreface/login/IArgLogin';
import { IAuthByPassword } from './intreface/login/IAuthByPassword';
import { IHookLoginOutput } from './intreface/login/IHookLoginOutput';
import { ILoginWithCode } from './intreface/login/ILoginWithCode';
import { IRequestCode } from './intreface/login/IRequestCode';

const useLogin = ({ status, setLoginStatus }: IArgLogin): IHookLoginOutput => {
  const { login: loginAuth } = useAuthContext();
  const { messageApi } = useMessages();
  const location = useLocation();
  const { state } = location as {
    state: { pathname?: string; state: unknown };
  };
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { trigger: sendCodeTr } = useSWRMutation(
    '/aim/sessions/request-code',
    sendCodeReq,
  );

  const { trigger: loginByCodeTr } = useSWRMutation(
    '/aim/api/v1/auth/otp',
    loginByCode,
  );

  const { trigger: authByEmailTr } = useSWRMutation(
    '/aim/auth',
    loginByPassword,
  );

  const sendCode = async (data: ILoginForm) => {
    try {
      const tempData: IRequestCode = {
        contactInfo: data.login,
        role: data.role as ERoles,
      };
      setLoginStatus(ELoginStatus.CodeSended);
      await sendCodeTr(tempData);
      setLoginStatus(ELoginStatus.CodeSent);
    } catch (e) {
      if ((e as any).response?.status === 404) {
        setLoginStatus(ELoginStatus.NotFound);
      } else {
        setLoginStatus(ELoginStatus.None);
      }
    }
  };

  const sendLoginWithCode = async (data: ILoginForm) => {
    const tempData: ILoginWithCode = {
      contactInfo: data.login,
      code: String(data.code),
      role: data.role as ERoles,
    };
    await loginByCodeTr(tempData);
  };

  const phoneLogin = async (data: ILoginForm, needRedirect = true) => {
    if (status === ELoginStatus.CodeSent) {
      await sendLoginWithCode(data);
      setLoginStatus(ELoginStatus.CodeVerified);
      if (loginAuth) {
        await loginAuth(data.role as ERoles);
        if (needRedirect) {
          navigate(UserRoutes[data?.role as ERoles], {
            state: state?.state,
          });
        }
      }
    } else {
      await sendCode(data);
    }
  };

  const authByEmail = (data: ILoginForm) => {
    if (data.login) {
      setLoginStatus(ELoginStatus.NeedPassword);
    }
  };

  const authByPassword = async (data: ILoginForm, needRedirect = true) => {
    const tempData: IAuthByPassword = {
      username: data.login,
      password: String(data.password),
      role: data.role as ERoles,
    };
    try {
      await authByEmailTr(tempData);
      if (loginAuth) {
        loginAuth(data.role as ERoles);
        if (needRedirect) {
          navigate(UserRoutes[data?.role as ERoles], {
            state: state?.state,
          });
        }
      }
    } catch (e) {
      if ((e as AxiosError).response?.status === 404) {
        setLoginStatus(ELoginStatus.NotFound);
      }
      setErrorMessage(((e as AxiosError).response?.data as any).message);
    }
  };

  const emailLogin = async (data: ILoginForm, needRedirect?: boolean) => {
    if (status === ELoginStatus.NeedPassword) {
      await authByPassword(data, needRedirect);
    } else {
      authByEmail(data);
    }
  };

  const login = async (data: ILoginForm, needRedirect?: boolean) => {
    if (isPhoneField(data.login)) {
      await phoneLogin(data, needRedirect);
    } else {
      await emailLogin(data, needRedirect);
    }
  };

  useEffect(() => {
    if (state?.pathname) {
      messageApi?.info({
        content:
          'Время пользовательской сессии истекло, пройдите повторно авторизацию',
        duration: 2,
      });
    }
  }, []);

  return {
    login,
    errorTimerMessage: errorMessage,
    setErrorTimerMessage: setErrorMessage,
  };
};

export default useLogin;
