import { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';

import { specApiInstance } from '../../../1_shared/api/apiInstance';
import { useAuthContext } from '../../../app/module/lib/hooks/useAuthContext';
import { getActiveWeek } from '../utils/getActiveWeek';

import { IEventsSchedule } from './interfaces/IEventsSchedule';
import { ISpecialistSlots } from './interfaces/ISpecialistSlots';

const useGetSchedule = () => {
  const [schedule, setSchedule] = useState<IEventsSchedule[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeWeek, setActiveWeek] = useState<Date>(getActiveWeek(dayjs()));
  const { user } = useAuthContext();

  const getEvents = useCallback(async () => {
    setIsLoading(true);
    const events: AxiosResponse<ISpecialistSlots> = await specApiInstance.get(
      `/ss/slots/v1/${user?.id}`,
      {
        params: {
          from: dayjs(activeWeek).format('YYYY-MM-DD'),
          to: dayjs(activeWeek).add(6, 'days').format('YYYY-MM-DD'),
          zoneOffset: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      },
    );

    if (events.data) {
      const eventsOnSchedule = Object.values(events.data).flatMap(event => [
        ...(event?.avalibleSlots.map(event => ({
          id: event.slotId,
          title: 'Свободный',
          start: dayjs(event.start).toDate(),
          end: dayjs(event.end).toDate(),
        })) || []),
        ...(event?.orderedSlots.map(event => ({
          id: event.slotId,
          title: event.clientName || 'Аноним',
          start: dayjs(event.start).toDate(),
          end: dayjs(event.end).toDate(),
        })) || []),
      ]);
      setSchedule(eventsOnSchedule);
      setIsLoading(false);
    }
  }, [activeWeek, user?.id]);

  const onNext = () => {
    setActiveWeek(dayjs(activeWeek).add(1, 'week').toDate());
  };

  const onPrev = () => {
    setActiveWeek(dayjs(activeWeek).subtract(1, 'week').toDate());
  };

  const addEvent = (slot: IEventsSchedule) => {
    setSchedule(prev => [...prev, slot]);
  };

  const fetchEvents = () => {
    getEvents();
  };

  useEffect(() => {
    if (user?.id) {
      getEvents();
    }
  }, [activeWeek, getEvents, user]);

  return {
    schedule,
    activeWeek,
    isLoading,
    onNext,
    addEvent,
    onPrev,
    fetchEvents,
  };
};

export default useGetSchedule;
