import { ISort } from './ISort';

export enum SessionType {
  PERSONAL_CONSULTATION= "PERSONAL_CONSULTATION",
  SUPERVISION = "SUPERVISION",
  GROUP = "GROUP",
}

export interface ISpecialistListFilters {
  experienceSortOrder?: ISort;
  keyThemes: string[];
  workWith: string;
  experience: string;
  sex: string | null;
  slotsFilter: {
    localTimeFrom: string;
    localTimeTo: string;
    zoneOffset: string;
    dayType: string | null;
    date: string;
    localTime: string;
  };
  price: string;
  moneyFilter: {
    minMoneyRate: number | null;
    maxMoneyRate: number | null;
  };
  specialties: string;
  specShowType: number;
  surname?: string;
  defaultSort?: boolean;
  sessionType: SessionType.SUPERVISION | null;
  workMethod: string[];
}

export interface ISpecialistListFiltersQuery {
  filter: ISpecialistListFilters;
}
