import {ReactElement} from 'react';
import {useNavigate} from 'react-router-dom';

import SpecialistPersonCardData from '2_entities/SpecialistPersonCardData/ui/SpecialistPersonCardData';
import {PreviewListImage} from '3_features';

import {ISlot} from "../../../1_shared/config/interfaces/ISlot";
import {RoutePath} from '../../../1_shared/config/routes';
import {configMedia} from '../../SpecialistCard/ui/config/configMedia';

import {ISpecPersonCards} from './interfaces/ISpecPersonCards';

import styles from './SpecialistPersonCard.module.scss';

const SpecialistPersonCard = ({ spec }: ISpecPersonCards): ReactElement => {
  const navigate = useNavigate();

  const onSubmit = (data?: ISlot) => {
    navigate(RoutePath.ORDER, { state: { id: spec.id, slot: data || spec.slots[0] }, });
  };
  return (
    <div
      key={spec.id}
      className={styles.root}
    >
      <PreviewListImage
        media={
          spec.mediaContentResponse?.length
            ? spec.mediaContentResponse
            : [configMedia]
        }
      />
      <SpecialistPersonCardData
        specialist={spec}
        onChange={onSubmit}
      />
    </div>
  );
};

export default SpecialistPersonCard;
