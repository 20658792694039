import { Dispatch, RefObject, useEffect, useState } from 'react';

import { ReactComponent as MicroOff } from '1_shared/assets/images/conference/microphone-off.svg';
import { ReactComponent as MicroOn } from '1_shared/assets/images/conference/microphone-on.svg';
import { ReactComponent as CameraOff } from '1_shared/assets/images/conference/video-recorder-off.svg';
import { ReactComponent as CameraOn } from '1_shared/assets/images/conference/video-recorder-on.svg';
import { ISlot } from '1_shared/config/interfaces/ISlot';
import { ConferenceTimeStatus } from '1_shared/constants/conference';
import { Button } from '1_shared/ui';
import CameraOffIndicator from '2_entities/ConferenceEntities/CameraOffIndicator/CameraOffIndicator';
import LocalMedia from '2_entities/ConferenceEntities/LocalMedia/LocalMedia';
import MicroOffIndicator from '2_entities/ConferenceEntities/MicroOffIndicator/MicroOffIndicator';
import PreparationHelp from '2_entities/ConferenceEntities/PreparationHelp/PreparationHelp';
import SessionState from '2_entities/ConferenceEntities/SessionState/SessionState';
import useConferenceController from '3_features/ConferenceController/model/useConferenceController';
import ConferenceExitButton from '3_features/ConferenceFeatures/ConferenceExitButton';
import ConferencePreEndIndicators from '3_features/ConferenceFeatures/ConferencePreEndIndicators';

import './ConferenceRoom.scss';
import styles from './ConferenceRoom.module.scss';
import { useAuthContext } from '../../../../app/module/lib/hooks/useAuthContext';
import { ERoles } from '../../../../1_shared/config/enums/ERoles';

const ConferenceRoom = ({
  slot,
  confStatus,
  isEnded,
  setIsEnded,
}: {
  confStatus: ConferenceTimeStatus;
  slot: ISlot;
  isEnded: boolean;
  setIsEnded: Dispatch<boolean>;
}) => {
  const [isSessionStarted, setStartSession] = useState(false);
  const { role } = useAuthContext();
  const {
    loading,
    videoElement,
    devices,
    onLockedDevicesChange,
    remoteMedia,
    remoteUser,
    onEnd,
    status,
    endpoints,
  } = useConferenceController({
    isSessionEnded: isEnded,
    isSessionStarted,
    setSessionEnded: setIsEnded,
    setStartSession,
    slot,
  });

  useEffect(() => {
    if (isEnded) {
      onEnd();
    }
  }, [isEnded]);

  useEffect(
    () => () => {
      onEnd();
    },
    [],
  );

  return (
    <>
      <div className={styles.remoteVideo} id="videoContainerCompanion">
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            zIndex: 100,
          }}
        >
          {role === ERoles.Spec && (
            <ConferencePreEndIndicators confStatus={confStatus} slot={slot} />
          )}
          {!!endpoints?.length && (
            <div className={styles.indicators}>
              {remoteMedia.video === 'removed' && <CameraOffIndicator />}
              {!!remoteUser &&
                status === 'not-alone' &&
                remoteMedia.voice === 'removed' && <MicroOffIndicator />}
            </div>
          )}
          <div className={styles.localVideo} id="videoContainerSelf">
            <LocalMedia
              lockedDevices={devices.lockedDevices}
              videoElement={videoElement as RefObject<HTMLVideoElement>}
            />
          </div>
          {!isSessionStarted && <PreparationHelp />}
          {!isSessionStarted && !loading && (
            <Button
              className={styles.startSessionButton}
              onClick={() => setStartSession(true)}
              size="large"
            >
              Присоединиться к встрече
            </Button>
          )}
          {isSessionStarted && !loading && (
            <SessionState
              status={status}
              remoteUser={remoteUser}
              remoteMedia={remoteMedia}
            />
          )}
        </div>
      </div>
      <div className={styles.controlPanel}>
        <div className={styles.controlPanelGroup}>
          <Button
            disabled={!isSessionStarted}
            onClick={() =>
              onLockedDevicesChange(devices.lockedDevices, 'microphone')
            }
          >
            {!devices.lockedDevices.microphone ? (
              <MicroOn className={styles.micro} />
            ) : (
              <MicroOff style={{ width: 24, height: 24, stroke: 'white' }} />
            )}
          </Button>
          <Button
            id="cam-control-btn"
            disabled={!devices.devices.camera}
            onClick={() =>
              onLockedDevicesChange(devices.lockedDevices, 'camera')
            }
          >
            {!devices.lockedDevices.camera ? (
              <CameraOn style={{ width: 24, height: 24, stroke: 'white' }} />
            ) : (
              <CameraOff style={{ width: 24, height: 24, stroke: 'white' }} />
            )}
          </Button>
        </div>
        <ConferenceExitButton setIsEnded={setIsEnded} />
      </div>
    </>
  );
};
export default ConferenceRoom;
