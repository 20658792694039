export const phoneConvert = (phone: string) =>
  phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 $2 $3 $4 $5');

export const phoneFormConvert = (phone: string) => {
  const tempPhone = phone.replaceAll(/[()\s-]/g, '').slice(0, 12);
  if (
    tempPhone.includes('(') &&
    tempPhone.includes('+') &&
    tempPhone.includes('-')
  ) {
    return tempPhone
      .replace(/(\+\d)(\(\d{3}\))(\d{3}-)(\d{2}-)(\d{2})/, '+7 $2 $3 $4 $5')
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll('-', '');
  }
  if (tempPhone.charAt(0) !== '+') {
    return tempPhone.replace(
      /(\d)(\d{3})(\d{3})(\d{2})(\d{2})/,
      '+7 $2 $3 $4 $5',
    );
  }

  return tempPhone.replace(/(\d)(\d{3})(\d{3})(\d{2})(\d{2})/, '7 $2 $3 $4 $5');
};

export const onKeyDownPhoneInput = (
  e: React.KeyboardEvent<HTMLInputElement>,
) => {
  const allowedKeys = [
    'Backspace',
    'Delete',
    'ArrowLeft',
    'ArrowRight',
    'ArrowUp',
    'ArrowDown',
    'Home',
    'End',
    'Tab',
    'Enter',
    'Escape',
    'Shift',
    'Control',
    'Alt',
    'Meta',
  ];

  if (
    allowedKeys.includes(e.key) ||
    e.ctrlKey ||
    e.metaKey ||
    /[0-9+\s()-]/.test(e.key)
  ) {
    return;
  }

  e.preventDefault();
};
