import { saveAs } from 'file-saver';
import useSWRMutation from 'swr/mutation';

import { mutation } from '../../../1_shared/api/apiInstance';
import { ERequestType } from '../../../1_shared/api/interfaces/ERequestType';

export const getCalendarEvent = mutation<null, any>(ERequestType.Get);

const useGetCalendarEvent = () => {
  let slotIdLink = '';

  const { trigger } = useSWRMutation(
    `/ss/api/v1/session-util/by-slot/${slotIdLink}/generate-ics`,
    getCalendarEvent,
    {
      onSuccess: data => {
        saveAs(
          new Blob([data], { type: 'text/calendar;charset=utf-8' }),
          'Запись на сессию.ics',
        );
      },
    },
  );

  const getSessionCalendarEvent = async (slotId?: string) => {
    if (slotId) slotIdLink = slotId;
    if (slotId) await trigger(null);
  };

  return getSessionCalendarEvent;
};

export default useGetCalendarEvent;
