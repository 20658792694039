import { z } from 'zod';

import { EContactType } from '../../../1_shared/config/enums/EContactType';

// export const schema = yup.object().shape({
//   name: yup.string().required('Полное имя обязательно'),
//   phone: yup
//     .string()
//     .required('Номер телефона обязателен')
//     .matches(/^\+7 \d{3} \d{3} \d{2} \d{2}$/, 'Некорректный номер телефона'),
//   chooseContactType: yup.string().required('Выберите способ связи'),
//   keyThemas: yup.array().of(yup.string()),
//   wellBeing: yup.string(),
//   time: yup.string(),
//   dayType: yup.string().nullable(),
//   haveExpWithPsy: yup.string(),
//   sex: yup.string().nullable(),
//   telegram: yup.string().min(5, 'Минимум 5 символов').nullable(),
// }) as yup.ObjectSchema<IAnketaForm>;

export const IAnketaFormSchema = z
  .object({
    name: z.string({ message: 'Полное имя обязательно' }),
    phone: z
      .string({ message: 'Номер телефона обязателен' })
      .regex(/^\+7 \d{3} \d{3} \d{2} \d{2}$/, {
        message: 'Некорректный номер телефона',
      }),
    chooseContactType: z.string({ message: 'Выберите способ связи' }),
    keyThemas: z.array(z.string()).optional(),
    wellBeing: z.string().optional(),
    time: z.string().optional(),
    dayType: z.string().optional(),
    haveExpWithPsy: z.string().optional(),
    sex: z.string().optional(),
    telegram: z.string().optional(),
  })
  .superRefine(({ chooseContactType, telegram }, ctx) => {
    if ((telegram?.length || '') > 1 &&
      chooseContactType === EContactType.Telegram &&
      !/^[a-z](?=(?:[0-9_]*[a-z]){0})[a-z0-9_]{4,64}$/.test(telegram || '')
    ) {
      return ctx.addIssue({
        code: 'custom',
        message: `Некорректно указан ник Телеграмм`,
        path: ['telegram'],
      });
    }
  });
