import 'app/styles/global/global.scss';
import './statistics.scss';

const Statistics = () => (
<section className="statistics">
  <div className="statistics__container container">
    <ul className="statistics__list">
      <li className="statistics__item">
        <span className="statistics__number">1000+</span>
        <p>человек нашли своего психолога</p>
      </li>
      <li className="statistics__item">
        <span className="statistics__number">100+</span>
        <p>сертифицированных специалистов</p>
      </li>
      <li className="statistics__item">
        <span className="statistics__number">8 лет</span>
        <p>средний опыт практики</p>
      </li>
      <li className="statistics__item">
        <span className="statistics__number">2 из 100</span>
        <p>кандидатов становится психологом платформы</p>
      </li>
    </ul>
  </div>
</section>
);

export default Statistics;