import { Suspense, useEffect, useMemo } from 'react';
import { Route, Routes as RoutesPath, useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Spin } from 'antd';

import useInterceptors from '../../../1_shared/api/hooks/useInterceptors';
import { ERoles } from '../../../1_shared/config/enums/ERoles';
import { routerConfig } from '../../config/router';
import NotificationContext from '../lib/context/NotificationContext';

const Routes = () => {
  const { contextHolder, messageApi } = useInterceptors();

  // UTM-functional
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.search.includes('utm')) {
      const params: { [x: string]: string } = {};
      const paramsStrings: null[] = location.search
        ?.split('?')?.[1]
        ?.split('&')
        ?.map(param => param?.split('='))
        ?.map(value => {
          params[value?.[0]] = value?.[1];
          return null;
        });
      if (paramsStrings?.length) {
        localStorage.setItem(
          'utm',
          JSON.stringify({ ...params, queryParams: location.search }),
        );
        navigate(location.pathname);
      }
    }
  }, [location, navigate]);

  const role = (localStorage.getItem('role') || ERoles.Unauthorized) as ERoles;

  const router = useMemo(
    () =>
      routerConfig
        .filter(
          route =>
            route.roles.includes(role) ||
            route.roles.includes(ERoles.Unauthorized),
        )
        .map(route => ({
          path: route.path,
          element: route.element,
        })),
    [role],
  );

  return (
    <section>
      <NotificationContext.Provider value={{ messageApi }}>
        <RoutesPath>
          {router.map(route => (
            <Route
              key={route.path}
              path={route.path}
              element={<Suspense fallback={<Spin />}>{route.element}</Suspense>}
            />
          ))}
        </RoutesPath>
        {contextHolder}
      </NotificationContext.Provider>
    </section>
  );
};

export default Routes;
