import { PropsWithChildren, ReactElement } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ISpecialistListFilters } from '1_shared/config/interfaces';

const FilterProvider = ({ children }: PropsWithChildren): ReactElement => {
  const methods = useForm<ISpecialistListFilters>({
    defaultValues: {
      defaultSort: true,
    },
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};

export default FilterProvider;
