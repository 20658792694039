import { Content, Footer, Page, Survey, ToRead } from '1_shared/ui';
import { ProfileUser } from '2_entities';
import { Header, PcClientTabs } from '4_widgets';

const PcClient = () => (
  <Page>
    <Header />
    <Content>
      <ProfileUser />
    </Content>
    <PcClientTabs />
    <Survey />
    <ToRead />
    <Footer />
  </Page>
);

export default PcClient;
