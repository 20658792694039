import dayjs from 'dayjs';

import { ERoles } from '1_shared/config/enums/ERoles';
import { ISlot } from '1_shared/config/interfaces/ISlot';
import { ConferenceTimeStatus } from '1_shared/constants/conference';

import TimerSession from '../TimerSessions/ui/TimerSession';

import styles from './ConferenceFeatures.module.scss';

const ConferencePreEndIndicators = ({
  confStatus,
  slot,
}: {
  confStatus: ConferenceTimeStatus;
  slot: ISlot;
}) => {
  const role = localStorage.getItem('role');
  const end = dayjs(slot.timePoint).add(slot.duration as number, 'minutes');

  return (
    <>
      {confStatus === 'preEnd' && role === ERoles.Spec && (
        <div className={styles.preEndIndicator}>
          <div>Сеанс завершится через:</div>
          <TimerSession end={end.format('YYYY-MM-DD HH:mm:ss')} />
        </div>
      )}
      {confStatus === 'postEnd' && role === ERoles.Spec && (
        <div className={styles.preEndIndicator}>
          <div>Время вашего сеанса истекло</div>
        </div>
      )}
    </>
  );
};
export default ConferencePreEndIndicators;
