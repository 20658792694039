import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { RcFile } from 'antd/es/upload';
import cn from 'classnames';

import { specApiInstance } from '1_shared/api/apiInstance';
import { ESpecStatus } from '1_shared/config/enums/ESpecStatus';
import { RoutePath } from '1_shared/config/routes';

import { IFirstFormSpec } from '../../../1_shared/config/interfaces/IFirstFormSpec';
import { firstFormTabsSchema } from '../../../1_shared/config/validationSchemas/firstFormTabsSchema';
import { Button, Tabs } from '../../../1_shared/ui';
import useGetTabItems from '../module/useGetTabItems';

import styles from './FirstFormTabs.module.scss';

export enum EPhotoType {
  FILE = 'contentFiles',
  AVATAR = 'avatar',
}

export const uploadPhoto = async (
  file: RcFile,
  specialistId: string,
  type = EPhotoType.AVATAR,
) => {
  try {
    const formData = new FormData();
    formData.append(type, file);
    const response = await specApiInstance.post(
      type === EPhotoType.AVATAR
        ? `/content/specialist-content/upload/avatar?specialistId=${specialistId}`
        : `/content/specialist-content/multiple/upload-by-param?specialistId=${specialistId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  } catch (error) {
    throw new Error('File upload error');
  }
};

const FirstFormTabs = () => {
  const [activeTab, setActiveTab] = useState<string>('1');
  const [isDone, setIsDone] = useState(false);
  const methods = useForm({
    mode: 'onBlur',
    // @ts-ignore TODO: fix resolver
    resolver: yupResolver<IFirstFormSpec>(firstFormTabsSchema),
  });
  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const tabItems = useGetTabItems(setActiveTab);

  const navigate = useNavigate();

  const goPrevious = () => {
    setActiveTab('1');
  };

  const submitBaseFormData = async (data: IFirstFormSpec['data']) => {
    const response = await specApiInstance.post(
      '/spec/specialist/fill-form',
      data,
    );
    return response?.data;
  };

  const onSubmit = async (data: IFirstFormSpec) => {
    try {
      setIsDone(true);
      await submitBaseFormData(data.data);
    } catch (baseDataError) {
      console.error('Base data error:', baseDataError);
      setIsDone(false);
      return;
    }

    const profileResponse = await specApiInstance.get(
      `/spec/specialist/profile`,
    );
    localStorage.setItem('user', JSON.stringify(profileResponse.data));

    if (profileResponse.data.status === ESpecStatus.NotActive) {
      navigate(RoutePath.FIRST_FORM);
    } else {
      navigate(RoutePath.MAIN);
    }
  };

  return (
    <form className={styles.root} onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <Tabs
          classNameTabs={styles.tabs}
          items={tabItems}
          disabledToggle
          activeTab={activeTab}
          defaultActiveTabIndex="1"
        />
        <footer
          className={cn(styles.btnWrapper, {
            [styles.btnFirstWrap]: tabItems && activeTab === tabItems[0].key,
          })}
        >
          {tabItems && activeTab !== tabItems[0].key && (
            <Button
              className={styles.btnPrevios}
              type="secondary"
              onClick={goPrevious}
            >
              НАЗАД
            </Button>
          )}
          {tabItems && activeTab === tabItems[tabItems.length - 1].key && (
            <Button
              htmlType="submit"
              className={styles.btnSubmit}
              type="primary"
              disabled={!isValid || isDone}
            >
              ОТПРАВИТЬ
            </Button>
          )}
        </footer>
      </FormProvider>
    </form>
  );
};

export default FirstFormTabs;
