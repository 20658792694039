import { ReactElement, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import TextArea from 'antd/es/input/TextArea';
import useMessage from 'antd/es/message/useMessage';
import cn from 'classnames';
import useSWRMutation from 'swr/mutation';

import filterOption from '../../../../1_shared/helpers/filterOption';
import { Button, Input, Select, Typography } from '../../../../1_shared/ui';
import { CustomTypography } from '../../../../1_shared/ui/CustomTypography';
import { useAuthContext } from '../../../../app/module/lib/hooks/useAuthContext';
import { saveLinkForOldBackend } from '../../constants/saveLinkForOldBackend';
import { userObjectConverterMainInfo } from '../../helpers/userObjectConverter';
import { editProfileMainInfo } from '../../model/service/specEditService';
import { mergeObjects } from '../OtherInformation/OtherInformation';

import { IMainInfo, IMainInfoSchema } from './interfaces/IMainInfo';
import useSpecialistEditProfileMainInfo from './model/useSpecialistEditProfileMainInfo';

import styles from '../Credentials/SpecialistEditProfileCredentials.module.scss';

const SpecialistEditProfileMainInfo = (): ReactElement => {
  const { setSpecUser } = useAuthContext();
  const user = JSON.parse(String(localStorage.getItem('user')));
  const [messageApi, contextHolder] = useMessage();
  const isMobile = (window.navigator as any)?.userAgentData?.mobile;

  const [onSelect, setOnSelect] = useState(false);

  const methods = useForm<IMainInfo>({
    resolver: zodResolver(IMainInfoSchema),
    defaultValues: userObjectConverterMainInfo(user),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;
  const { keyThemasMemo, workWithMemo, workMethodsMemo, specMemo } =
    useSpecialistEditProfileMainInfo();

  const { trigger, isMutating, error } = useSWRMutation(
    saveLinkForOldBackend,
    editProfileMainInfo,
  );

  const onSubmit = async (data: IMainInfo) => {
    await trigger({
      work_since: data?.work_since,
      about_yourself: data?.about_yourself,
      briefAboutYourself: data.briefAboutYourself,
      specialitiesIds: data?.specialities || [],
      workMethodsIds: data?.work_methods || [],
      keyThemesIds: data?.key_themes || [],
      workWithsIds: data?.work_withs || [],
    });

    if (data && !error && setSpecUser) {
      setSpecUser(mergeObjects(user, data));
      return messageApi.open({
        type: 'success',
        content: 'Данные профиля отредактированы!',
      });
    }

    return messageApi.open({
      type: 'warning',
      content: 'Сохранить изменения не удалось!',
    });
  };

  const onSelectFocus = () => {
    setOnSelect(true);
  };

  const onSelectBlur = () => {
    setOnSelect(false);
  };
  console.log(cn(styles.root, { [styles.fixed]: onSelect }));

  return (
    <FormProvider {...methods}>
      <div className={cn(styles.root, { [styles.fixed]: onSelect })}>
        <Typography type="title">Основная информация</Typography>
        <div className={styles.row}>
          <Typography type="description">
            Опыт консультирования с (год)
            <span className={styles.required_asterisk}>*</span>
          </Typography>
          <Controller
            name="work_since"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type="number"
                status={errors.work_since ? 'error' : undefined}
              />
            )}
          />
          {errors?.work_since && (
            <CustomTypography type="description" className={styles.error}>
              {errors?.work_since?.message}
            </CustomTypography>
          )}
        </div>
        <div className={styles.row}>
          <Typography type="description">
            Специализация<span className={styles.required_asterisk}>*</span>
          </Typography>
          <Controller
            control={control}
            name="specialities"
            render={({ field }) => (
              <Select
                {...field}
                allowClear
                listHeight={isMobile ? 130 : undefined}
                dropdownStyle={{ minWidth: 250, maxWidth: 380 }}
                onFocus={onSelectFocus}
                onBlur={onSelectBlur}
                mode="multiple"
                maxTagCount={1}
                options={specMemo}
                filterOption={filterOption}
                status={errors.specialities ? 'error' : undefined}
                defaultValue={field.value}
                onChange={value => methods.setValue('specialities', value)}
                onClear={() => methods.setValue('specialities', [])}
                popupMatchSelectWidth={false}
                dropdownAlign={{ offset: [0, 4] }}
              />
            )}
          />
          {errors?.specialities && (
            <CustomTypography type="description" className={styles.error}>
              {errors?.specialities?.message}
            </CustomTypography>
          )}
        </div>
        <div className={styles.row}>
          <Typography type="description">
            Работаете по методам
            <span className={styles.required_asterisk}>*</span>
          </Typography>
          <Controller
            control={control}
            name="work_methods"
            render={({ field }) => (
              <Select
                {...field}
                style={{
                  minWidth: isMobile ? 0 : undefined,
                  maxWidth: isMobile ? '70vw' : undefined,
                }}
                allowClear
                listHeight={isMobile ? 130 : undefined}
                dropdownStyle={{ minWidth: 250, maxWidth: '75vw' }}
                mode="multiple"
                maxTagCount={1}
                options={workMethodsMemo}
                filterOption={filterOption}
                status={errors.work_methods ? 'error' : undefined}
                defaultValue={field.value}
                onChange={value => methods.setValue('work_methods', value)}
                onClear={() => methods.setValue('work_methods', [])}
                popupMatchSelectWidth={false}
              />
            )}
          />
          {errors?.work_methods && (
            <CustomTypography type="description" className={styles.error}>
              {errors?.work_methods?.message}
            </CustomTypography>
          )}
        </div>
        <div className={styles.row}>
          <Typography type="description">
            Ключевые темы<span className={styles.required_asterisk}>*</span>
          </Typography>
          <Controller
            control={control}
            name="key_themes"
            render={({ field }) => (
              <Select
                {...field}
                allowClear
                listHeight={isMobile ? 130 : undefined}
                dropdownStyle={{ minWidth: 250, maxWidth: 380 }}
                mode="multiple"
                maxTagCount={1}
                options={keyThemasMemo}
                filterOption={filterOption}
                status={errors.key_themes ? 'error' : undefined}
                defaultValue={field.value}
                onChange={value => methods.setValue('key_themes', value)}
                onClear={() => methods.setValue('key_themes', [])}
                dropdownAlign={{ offset: [0, 4] }}
              />
            )}
          />
          {errors?.key_themes && (
            <CustomTypography type="description" className={styles.error}>
              {errors?.key_themes?.message}
            </CustomTypography>
          )}
        </div>
        <div className={styles.row}>
          <Typography type="description">
            Работаете с<span className={styles.required_asterisk}>*</span>
          </Typography>
          <Controller
            control={control}
            name="work_withs"
            render={({ field }) => (
              <Select
                {...field}
                allowClear
                listHeight={isMobile ? 130 : undefined}
                dropdownStyle={{ minWidth: 250, maxWidth: 380 }}
                mode="multiple"
                maxTagCount={1}
                options={workWithMemo}
                filterOption={filterOption}
                status={errors.work_withs ? 'error' : undefined}
                defaultValue={field.value}
                onChange={value => methods.setValue('work_withs', value)}
                onClear={() => methods.setValue('work_withs', [])}
                popupMatchSelectWidth={false}
                dropdownAlign={{ offset: [0, 4] }}
              />
            )}
          />
          {errors?.work_withs && (
            <CustomTypography type="description" className={styles.error}>
              {errors?.work_withs?.message}
            </CustomTypography>
          )}
        </div>
        <div className={styles.row}>
          <Typography type="textM">Коротко о себе</Typography>
          <Typography type="description">
            Кратко опишите свою работу.
          </Typography>
          <Typography type="description">
            Это поможет клиенту составить первое впечатление о Вас.
          </Typography>
          <Controller
            control={control}
            name="briefAboutYourself"
            render={({ field }) => <TextArea {...field} rows={3} />}
          />
        </div>
        <div className={styles.row}>
          <Typography type="textM">Цитата о себе</Typography>
          <Typography type="description">
            Напишите в свободной форме о себе.
          </Typography>
          <Typography type="description">
            Вы можете ответить на один из этих вопросов:
          </Typography>
          <Typography type="description">
            1. Почему вы решили стать психологом
          </Typography>
          <Typography type="description">
            2. Что для вас психотерапия
          </Typography>
          <Typography type="description">
            3. В какой методе вы работаете и почему его выбрали
          </Typography>
          <Controller
            control={control}
            name="about_yourself"
            render={({ field }) => (
              // TODO: fix textarea
              <TextArea {...field} rows={4} />
            )}
          />
        </div>
        <Button
          type="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={isMutating}
        >
          Сохранить
        </Button>
      </div>
      {contextHolder}
    </FormProvider>
  );
};

export default SpecialistEditProfileMainInfo;
