import {Dispatch} from "react";

import { ReactComponent as HangUp } from '1_shared/assets/images/conference/hang-up.svg';
import { Button } from '1_shared/ui';

const ConferenceExitButton = ({setIsEnded}: {setIsEnded: Dispatch<boolean>}) => (
    <Button
      danger
      onClick={() =>
        setIsEnded(true)
      }
    >
      <HangUp width={24} height={24} fill='#ffffff' />
    </Button>
  );
export default ConferenceExitButton;
